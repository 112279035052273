:global(.shade_container) {
  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      .root {
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &:hover:active,
  &:active {
    .root {
      background: var(--shade-gradient-col1);

      &:before,
      &:after {
        opacity: 0;
      }
    }
  }
  &:focus-visible {
    .root {
      --shade-mouse-x: 50 !important;
      --shade-mouse-y: 50 !important;
    }
  }
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--shade-gradient-col2);
  transition: background 200ms linear;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 200ms linear;
  }

  &:before {
    background: linear-gradient(105.32deg, var(--shade-gradient-col1) 7.07%, var(--shade-gradient-col2) 87.19%);
  }

  &:after {
    opacity: 0;
    background-image: radial-gradient(circle at center, var(--shade-gradient-col1) 10%, var(--shade-gradient-col2) 80%);
    background-size: 200% 200%;
    background-position: calc((100 - var(--shade-mouse-x, 0)) * 1%) calc((100 - var(--shade-mouse-y, 0)) * 1%);
  }
}
